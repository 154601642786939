export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "target_host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target Host"])}
      },
      "zh": {
        "target_host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标主机"])}
      }
    }
  })
}
