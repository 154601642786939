export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "verbose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trace in details"])},
        "verbose_true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May include sensitive data in the trace, e.g. path to files, user name, password."])},
        "verbose_false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some information is hidden when there is a exception, which makes it more difficult to fix the issue."])},
        "vvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vvv"])},
        "vvv_true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["includes more information in log"])},
        "vvv_false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usually false"])},
        "installMirror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install repository"])}
      },
      "zh": {
        "verbose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示任务参数"])},
        "verbose_true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日志中会包含部分敏感信息，例如：文件路径、用户名密码等"])},
        "verbose_false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分错误信息不能完整展示，使得出错时排查问题更困难"])},
        "vvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示调试信息"])},
        "vvv_true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日志中会包含最详细的信息"])},
        "vvv_false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通常设置为 false"])},
        "installMirror": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安装软件源"])}
      }
    }
  })
}
