export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "titleRepo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OS mirrors"])},
        "repoDescription1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You muse provide a mirror address (e.g. CentOS yum mirror, Ubuntu apt mirror) that all the nodes in your mirror could access."])},
        "repoDescription2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usually, a enterprise has its own os mirror, if not, Kuboard alos provide a wizard, so that you can create an os mirror quickly."])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mirror/Repo Url"])},
        "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nodes use this url to access the OS Mirror"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Ready"])},
        "basics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base information"])}
      },
      "zh": {
        "titleRepo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OS 软件源"])},
        "repoDescription1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您必须定义您集群机器可以访问的操作系统软件源地址（例如 CentOS 的 yum 源、Ubuntu 的 apt 源等）以供使用"])},
        "repoDescription2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通常企业都有自己的常用操作系统的本地软件源，Kuboard 提供向导，帮助您快速设置一个操作系统软件源"])},
        "provision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部署软件源"])},
        "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["软件源地址"])},
        "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["各 K8S 节点访问此软件源时所使用的 URL 地址"])},
        "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已创建"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用"])},
        "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不可用"])},
        "basics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本信息"])}
      }
    }
  })
}
