export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "addSshKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Private Key"])}
      },
      "zh": {
        "addSshKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加私钥"])},
        "ansible_host_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["必须在节点级别设置"])}
      }
    }
  })
}
