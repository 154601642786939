export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kubespray params."])},
        "params_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following params will be used in kubespray if the os_mirror is selected."])}
      },
      "zh": {
        "params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kubespray 参数"])},
        "params_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果被选中，以下参数将用于 kubespray。"])}
      }
    }
  })
}
