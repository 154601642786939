export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "special_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequncy to sync"])},
        "KB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KiloBytes/Thread"])},
        "kb": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["about ", _interpolate(_named("kb")), " Mb/s per thread"])},
        "threadCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thread Count"])},
        "totalkb": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["about ", _interpolate(_named("kb")), " Mb/s in total"])},
        "releases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Releases included"])}
      },
      "zh": {
        "special_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从上游同步的频率"])},
        "KB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["千字节每秒每线程"])},
        "kb": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["大约 ", _interpolate(_named("kb")), " Mb/s 每线程"])},
        "threadCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个下载线程"])},
        "totalkb": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["总共大约 ", _interpolate(_named("kb")), " Mb/s"])},
        "release": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包含的版本"])}
      }
    }
  })
}
